<template>
<div>
      <!-- Breadcrumb start -->
      <ol class="breadcrumb">
        <li class="breadcrumb-item">Settings</li>
      </ol>
      <!-- Breadcrumb end -->

<b-card no-body>
<b-tabs card content-class="mt-3">
    <b-tab title="General" active>
        <inline-form-row label="Company Name" type="text" v-model="settings.name" :errors="errors['name'] " helpText="Your Company Full Name"/>
        <inline-form-row label="Welcome Message" type="text" rows="3" v-model="settings.welcome_msg" :errors="errors['welcome_msg'] " helpText="Message that will appear at login page"/>
    </b-tab>
    <b-tab title="Contact">
        <inline-form-row label="E-mail" type="text" v-model="settings.email" :errors="errors['email'] " helpText="Business / Billing departement email address - will appear on all generated invoices"/>
        <inline-form-row label="Phone" type="text" v-model="settings.phone" :errors="errors['phone'] " helpText="Business / Billing departement phone number (no specific format)"/>
        <BMSAddress v-model="settings.address" />
    </b-tab>
    <b-tab title="Billing">
        <BMSCurrencySelector label="Currency" v-model="settings.currency" :errors="errors['currency']" />
        <inline-form-row label="Fiscal Year End" _type="b-form-datepicker" v-model="settings.fiscal_date" :errors="errors['fiscal_date']" helpText="Your business' last fiscal day"/>
        <inline-form-row label="Quote Term" type="text" v-model="settings.quote_term" :errors="errors['quote_term'] " helpText="Your default quote validity term (usually 30 days) - The string will appear as is on all of your bottom quotes"/>
        <inline-form-row label="Invoice Term" type="text" v-model="settings.invoice_term" :errors="errors['invoice_term'] " helpText="You net payment terms - The string will appear at the bottom of all your invoices"/>
    </b-tab>
    <b-tab title="Banking">
        <b-button class="float-right" variant="success" @click="addBankAccount()">
            <b-icon icon="plus-circle" /> Add Bank Account
        </b-button>
        <br />
        <br />
        <div class="accordion" role="tablist">
          <b-card
              v-for="(bank_account, index) in bank_accounts"
              :title="bank_account.name"
              :key="index"
              no-body
              class="mb-1"
            >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block v-b-toggle="'accordion-' + index" variant="info">Bank Account: {{ bank_account.label }}</b-button>
            </b-card-header>
            <b-collapse :id="'accordion-' + index" accordion="my-accordion" role="tabpanel">
              <b-card-body>
                <inline-form-row label="Bank Label" type="text" v-model="bank_account.label" :errors="errors['bank_account.label'] " helpText="Bank Given Name - Add details to differienciate your bank accounts"/>
                <inline-form-row label="Bank Name" type="text" v-model="bank_account.name" :errors="errors['bank_account.name'] " helpText="Official Bank name in full"/>
                <inline-form-row label="Bank Number" type="text" v-model="bank_account.number" :errors="errors['bank_account.number'] " helpText="Bank number / Institution Number (3 digit number)"/>
                <inline-form-row label="Bank Branch" type="text" v-model="bank_account.branch" :errors="errors['bank_account.branch'] " helpText="Bank branch number "/>
                <inline-form-row label="Bank Account Number" type="text" v-model="bank_account.account_number" :errors="errors['bank_account.account_number'] " helpText="Your business' bank account number"/>
                <inline-form-row label="Bank SWIFT/BIC" type="text" v-model="bank_account.swift_bic" :errors="errors['bank_account.swift_bic'] " helpText="Used to identify banks and financial institutions worldwide. A SWIFT/BIC code consists of 8-11 characters and follows a format that identifies your bank, country, location, and branch."/>
                <inline-form-row label="Bank Phone Number" type="text" v-model="bank_account.phone" :errors="errors['bank_account.phone'] " helpText="Bank phone number"/>
                <BMSAddress v-model="bank_account.address" label_prefix="Bank" />
                <BMSCurrencySelector label="Bank Currency" v-model="bank_account.currency" :errors="errors['bank_account.currency']" />
                <div class="float-right">
                    <b-button variant="success" @click="onSaveBankAccount(bank_account, index)">Save Bank Account</b-button>
                    <b-button variant="danger" @click="onDeleteBankAccount(bank_account, index)">Delete Bank Account</b-button>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

    </b-tab>
</b-tabs>
<template #footer>
    <b-button variant="success" @click="onSaveSettings()" class="float-right">Save Settings</b-button>
</template>

</b-card>
</div>
</template>

<script>

import { updateSettings, getSettings, getBankAccounts,
    deleteBankAccount,
    createBankAccount,
    updateBankAccount } from '@/api/settings.api'

import BMSCurrencySelector from '@/components/BMSCurrencySelector'
import BMSAddress from '@/components/BMSAddress'
import InlineFormRow from '@/components/InlineFormRow'
import crudMixin from '@/api/crud'

export default {
    mixins: [crudMixin],
    data() {
        return {
            tab: 'general',
            settings: {
                address: {}
            },
            bank_accounts: []
        }
    },
    mounted() {
        this.getSettings();
    },
    methods: {
        getSettings() {
            this.call(
                getSettings(),
                (res) => {
                    res.data.settings.forEach(item => {
                        this.settings[item.name] = item.value;
                    });

                    // Address/Bank/Taxes management (type: JSON)
                    this.settings.address = this.decodeJSON(this.settings.address, {});
                }
            )
            this.call(
                getBankAccounts(),
                (res) => {
                    this.bank_accounts.splice(0)
                    res.data.bank_accounts.forEach(item => {
                        this.bank_accounts.push(item)
                    })
                }
            )
        },
        decodeJSON(val, empty) {
            try {
                return JSON.parse(val);
            } catch (e) {
                return empty;
            }
        },
        addBankAccount() {
            this.bank_accounts.push({'address': {}});
        },
        onSaveBankAccount: function(bank_account, index) {
            if (bank_account.id) {
                var apiCall = updateBankAccount(bank_account.id, bank_account)
            } else {
                apiCall = createBankAccount(bank_account)
            }
            this.saveObject(
                apiCall,
                (res) => {
                    // Replaces 1 element at index index
                    this.bank_accounts.splice(index, 1, res.data.bank_account)
                }
            )
        },
        onDeleteBankAccount: function(bank_account, index) {
            if (bank_account.id) {
                this.deleteObject(
                    deleteBankAccount(bank_account.id)
                )
            }
            this.bank_accounts.splice(index, 1)
        },
        onSaveSettings() {
            // Convert to FormData
            var formData = new FormData();
            for (const [key, value] of Object.entries(this.settings)) {
                if (['address'].includes(key)) {
                    formData.append(key, JSON.stringify(value));
                    continue ;
                }

                formData.append(key, value);
            }
            this.saveObject(
                updateSettings(formData),
                () => {
                    this.getSettings()
                }
            )
        },
    },
    components: {
        BMSAddress,
        InlineFormRow,
        BMSCurrencySelector
    }
}

</script>
